import 'bootstrap/dist/css/bootstrap.css';
import '../css/styles.css';
import {Col, Row, Container, Button} from 'react-bootstrap';
import Video from '../media/video/NUIT/nuit_javascript.mov';
import pic1 from '../media/image/Migros/1.png';
import pic2 from '../media/image/Migros/2.png';
import pic3 from '../media/image/Migros/3.png';
import pic4 from '../media/image/Migros/4.png';
import pic5 from '../media/image/Migros/5.png';
import pic6 from '../media/image/Migros/6.png';
import pic7 from '../media/image/Migros/7.jpg';
import { useState, useRef } from 'react';
import {Helmet} from 'react-helmet';
import '../translation/i18n.js';
import {useTranslation} from 'react-i18next';
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Counter from "yet-another-react-lightbox/plugins/counter";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/counter.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";


function Gallery() {


    const [open, setOpen] = useState(false);
    const captionsRef = useRef(null);
    const {t} = useTranslation()
    return (
        <>

            <Helmet>

                <title>Pangolin Tech │ {t('workpageTitle')}</title>
                <meta
                    name="description"
                    content="Yaratıcı çözümler ve teknolojiyle harmanlanan iş portföyümüz, yazılım geliştirme, tasarım, güvenlik ve donanım alanlarında uzmanlığımızı sergiliyor. Projelerimiz, müşterilerimizin benzersiz ihtiyaçlarına odaklanarak iş süreçlerini optimize etmelerine ve rekabet avantajı elde etmelerine yardımcı oluyor."
                />
                <meta
                    name="keywords"
                    content="Proje Portföyü, Yazılım Geliştirme Projeleri, Tasarım Çalışmaları, Güvenlik Projeleri, Donanım Çözümleri, Teknoloji Projeleri, Özel Yazılım Projeleri"
                />
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
            </Helmet>

       

            <section id="works">
                <div className="products">
                    <Row>
                        <Col>
                            <h1>{t('worksTitle')}</h1>

                            <video
                                controls
                                src={Video}
                                autoPlay
                                loop
                            />
                            <p>
                                {t('worksDescription')}
                            </p>
                        </Col>
                    </Row>

                </div>
                <div className="products">
                    <Row>
                        <Col>
                            <h1>{t('worksTitle2')}</h1>  
                        <Container className='app-image'>
                        <Row>
                        <Col sm={"12"} md={"12"} lg={"6"}>
               
                            <img
                               src={pic1}
                               alt=''
                            />

                        </Col>
                        <Col sm={"12"} md={"12"} lg={"6"}>
               
                            <img
                               src={pic2}
                               alt=''
                            />

                        </Col>
                        </Row>
                        </Container>

                        <Button variant='dark' onClick={() => setOpen(true)}>
                             {t('galleryButton')}
                        </Button>

                            <Lightbox
                            open={open}
                            plugins={[Captions,Counter,Fullscreen,Zoom,Thumbnails]}
                            captions={{ ref: captionsRef }}
                   close={() => setOpen(false)}
                   slides={[
                   { 
                    src: pic1,
                    description: t('carouselTitle'),
                    width: 1920,
                    height: 1080
                   },
                   { 
                    src: pic2,
                    description: t('carouselTitle2'),
                    width: 1920,
                    height: 1080
                   },
                   { 
                    src: pic3,
                    description: t('carouselTitle3'),
                    width: 1920,
                    height: 1080
                   },
                   { 
                    src: pic4,
                    description: t('carouselTitle4'),
                    width: 1920,
                    height: 1080
                   },
                   { 
                    src: pic5,
                    description: t('carouselTitle5'),
                    width: 1920,
                    height: 1080
                   },
                   { 
                    src: pic6,
                    description: t('carouselTitle6'),
                    width: 1920,
                    height: 1080
                   },
                   { 
                    src: pic7,
                    description: t('carouselTitle7'),
                    width: 1920,
                    height: 1080
                   },

                              ]}
                              />

                            <p>
                                {t('worksDescription2')}
                            </p>
                        </Col>
                    </Row>

                </div>
                <div className='embed-responsive products'>
                    <Row>
                        <h1>{t('worksTitle3')}</h1>
                        <div className='col-sm-12 col-md-12 col-lg-12'>
                            <iframe className='embed-Video'
                                    src="https://www.youtube.com/embed/PLpsL9OaOvI"
                                    title='Treadmill'
                                    referrerPolicy="origin"
                                    allowFullScreen
                            />
                            <p>
                                {t('worksDescription3')}
                            </p>
                        </div>
                    </Row>
                </div>
            </section>
        </>
    );


}

export default Gallery;