import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';



const languages = ["tr", "en"];

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
  fallbackLng: 'tr',
  debug: true,
  whitelist: languages,
  react: {
    useSuspense: false
  },
  resources: {
     en: {
       translation: {
        "services": "Services",
        "works": "Works",
        "contact": "Contact",
        "description": "Immerse yourself in a world of dynamic possibilities with our Software Development services. Our skilled team excels in Front-end and Back-end development, ensuring a seamless fusion of captivating user interfaces and robust, scalable architectures. Dive into the realm of Game Development, where we bring virtual worlds to life, crafting engaging and immersive gaming experiences that push the boundaries of creativity and technology.",
        "description2": "Elevate your digital vision with our Software Design services. Our creative team specializes in translating ideas into visually stunning and user-centric designs. From intuitive user interfaces to cohesive user experiences, we bring a meticulous design approach to every project. Immerse your users in elegant and functional designs that not only meet but exceed their expectations. ",
        "description3": "Unleash the full potential of your software with our integrated Hardware Solutions. Beyond code and design, we offer tailored hardware solutions that complement and enhance your software offerings. From Internet of Things (IoT) integrations to custom hardware configurations, our expertise extends seamlessly from software to tangible solutions, providing a holistic approach for a connected and efficient user experience.",
        "description4": "Safeguard your digital assets and user data with our comprehensive Security services. In an era of evolving cyber threats, our team implements robust security measures to protect your software at every level. From secure coding practices to regular audits, we prioritize the confidentiality and integrity of your information. Partner with us to fortify your applications against vulnerabilities, providing a secure digital environment for your users and maintaining trust in your brand.",
        "title": "Software Development",
        "title2": "Software Design",
        "title3": "Hardware Solutions",
        "title4": "Security",
        "worksTitle": "Enhanced Connectivity and Analytics with NUIT Multitouch Table",
        "worksTitle2": "NUIW: Next-Gen Retail Display for Informed Shopping Experiences",
        "worksTitle3": "SprintPro X: Precision Treadmill with SpeedSync Display, EnduranceMetric Tracker, and Millisecond Precision Timer",
        "worksDescription": "NUIT, a sophisticated multitouch table, transcends traditional interactive features with its intelligent retail capabilities. Beyond its sleek design, NUIT serves as a data powerhouse by discreetly capturing and securely storing detailed information about product interactions and comparisons. Its seamless data transfer mechanism ensures real-time communication with servers, enabling access to the latest insights. The multitouch table's advanced analytics feature utilizes powerful algorithms to transform raw data into actionable insights, allowing retailers to understand customer behavior and identify patterns for strategic decision-making. NUIT's benefits for retail stores include uncovering localized user trends, optimizing stocking systems through data-driven decisions, and acting as a catalyst for strategic business improvements. By harnessing the hidden potential of data, NUIT revolutionizes how retailers approach customer interactions, stocking efficiency, and overall business strategies.",
        "worksDescription2": "NUIW, a revolutionary retail display developed in collaboration with Migros, transforms the shopping experience through cutting-edge hardware and software integration. Featuring a Barcode/QR Code reader, NUIW provides instant access to detailed product information like nutritional facts with a simple scan. Beyond information, it excels in intelligent recommendations, suggesting related products based on customer preferences and expanding product offerings to ensure a diverse and exciting shopping experience. For retailers, NUIW boosts sales by encouraging informed purchases, offering personalized shopping journeys, and increasing basket size through complementary item suggestions. It's not just a display; NUIW redefines how customers engage with products, delivering a dynamic and personalized shopping experience that benefits both retailers and shoppers.",
        "worksDescription3": "SprintPro X is a precision treadmill tailored for speed enthusiasts, boasting a maximum speed of 25 km per hour. Beyond a mere running machine, it serves as a natural interface to unlock the full potential of body motion, enhanced by the innovative SpeedSync Display, EnduranceMetric Tracker, and Millisecond Precision Timer. Propel yourself to new heights, track real-time speed data with precision, and push your limits using the EnduranceMetric Tracker to set benchmarks for your fitness journey. The Millisecond Precision Timer ensures unparalleled accuracy, perfect for competitions. Inspired by elite athletes, SprintPro X aligns with the standards of excellence and is crafted exclusively for Sportive, taking the spotlight at events like the Istanbul Sports and Marathon Fair. It's not just a treadmill; it's a fusion of fitness and technology, offering engaging challenges and exclusive prizes for champions.",
        "carouselTitle": "MOBILE-FRIENDLY SIMPLIFIED INTERFACE, PRODUCT GROUPS, ADVERTISEMENT DISPLAY AREA, QR READING BUTTON",
        "carouselTitle2": "WORD CLOUD",
        "carouselTitle3": "WORD CLOUD",
        "carouselTitle4": "PRODUCT DETAILS SECTION",
        "carouselTitle5": "PRODUCT DETAILS SECTION",
        "carouselTitle6": "PRODUCT DETAILS SECTION",
        "carouselTitle7": "PRODUCT RECOMMENDATIONS, DETAILED COMPARISON PANEL, ALTERNATIVE SUGGESTIONS",
        "galleryButton": "View Images",
        "workpageTitle": "Works",
        "contactPage": "Contact",
        "contactTitle": "Contact Us.",
        "contactLocation": "Location",
        "contactEmail": "Email Address",
        "contactPhone": "Phone Number",
        "footerRights": "All Rights Reserved."
       }
      },
       tr: {
        translation: {
          "services": "HİZMETLER",
          "works": "ÇALIŞMALAR",
          "contact": "İLETİŞİM",
          "description": "Kendi yazılım geliştirme hizmetlerimizle dinamik olasılıklar dünyasına dalarak, uzman ekibimiz ön yüz ve arka yüz geliştirmede üstün performans sergiler, çekici kullanıcı arayüzleri ile güçlü, ölçeklenebilir mimarilerin sorunsuz bir şekilde birleştirilmesini sağlar. Oyun Geliştirme alanına dalın, sanal dünyaları hayata geçiriyoruz, sınırları zorlayan yaratıcılık ve teknolojinin sınırsız oyun deneyimleri oluşturuyoruz.",
          "description2": "Yazılım Tasarım hizmetlerimizle dijital vizyonunuzu yükseltin. Yaratıcı ekibimiz, fikirleri görsel olarak etkileyici ve kullanıcı odaklı tasarımlara çevirme konusunda uzmanlaşmıştır. Sezgisel kullanıcı arayüzlerinden tutarlı kullanıcı deneyimlerine kadar, her projeye titiz bir tasarım yaklaşımı getiriyoruz. Kullanıcılarınızı zarif ve işlevsel tasarımlarla buluşturun, beklentilerini sadece karşılamakla kalmayıp aşan tasarımlara daldırın.",
          "description3": "Yazılımınızın tam potansiyelini entegre Donanım Çözümlerimizle ortaya çıkarın. Kod ve tasarımın ötesinde, yazılım tekliflerinizi tamamlayan ve geliştiren özel donanım çözümleri sunuyoruz. Nesnelerin İnterneti (IoT) entegrasyonlarından özel donanım yapılandırmalarına kadar uzanan uzmanlığımız, yazılımdan somut çözümlere sorunsuz bir şekilde genişliyor, bağlantılı ve verimli bir kullanıcı deneyimi için bütünsel bir yaklaşım sunuyor.",
          "description4": "Dijital varlıklarınızı ve kullanıcı verilerinizi kapsamlı Güvenlik hizmetlerimizle koruyun. Gelişen siber tehditlerin olduğu bir dönemde, ekibimiz yazılımınızı her seviyede korumak için güçlü güvenlik önlemleri uygular. Güvenli kodlama uygulamalarından düzenli denetimlere kadar, bilgilerinizin gizliliğini ve bütünlüğünü önceliğimiz olarak belirliyoruz. Uygulamalarınızı zayıflıklara karşı güçlendirmek için bizimle iş birliği yapın, kullanıcılarınız için güvenli bir dijital ortam sağlayın ve markanıza duyulan güveni sürdürün.",
          "title": "Yazılım Geliştirme",
          "title2": "Yazılım Tasarlama",
          "title3": "Yazılım Çözümleri",
          "title4": "Güvenlik",
          "worksTitle": "Artırılmış Bağlantı ve Analitik İmkanlarıyla NUIT Çoklu Dokunmatik Masa",
          "worksTitle2": "NUIW: Bilgilendirilmiş Alışveriş Deneyimleri İçin Son Nesil Perakende Ekranı",
          "worksTitle3": "SprintPro X: SpeedSync Ekranı, Dayanıklılık Ölçüm Cihazı ve Milisaniye Hassasiyetli Zamanlayıcı ile Hassas Koşu Bandı",
          "worksDescription": "NUIT, sofistike bir çoklu dokunmatik masa, geleneksel etkileşimli özellikleri aşarak akıllı perakende yetenekleri sunar. Şık tasarımının ötesinde, NUIT ürün etkileşimleri ve karşılaştırmalarıyla ilgili detaylı bilgileri gizlice yakalar ve güvenli bir şekilde depolar. Sorunsuz veri transfer mekanizması, gerçek zamanlı iletişim sağlayarak en son bilgilere erişimi mümkün kılar. Çoklu dokunmatik masanın gelişmiş analitik özelliği güçlü algoritmaları kullanarak ham veriyi eyleme geçirir, perakendecilere müşteri davranışlarını anlama ve stratejik kararlar için desenleri belirleme imkanı tanır. NUIT'nin perakende mağazaları için sağladığı faydalar arasında lokal kullanıcı trendlerini ortaya çıkarma, veriye dayalı kararlarla stok sistemlerini optimize etme ve müşteri etkileşimleri, stok verimliliği ve genel iş stratejilerine yaklaşımı devrim niteliğindedir. Verinin gizli potansiyelini kullanarak, NUIT perakendecilere müşteri etkileşimleri, stok verimliliği ve genel iş stratejilerine yaklaşımda devrim yaratır.",
          "worksDescription2": "NUIW, Migros ile işbirliği içinde geliştirilen devrim niteliğinde bir perakende ekranıdır ve alışveriş deneyimini çağdaş donanım ve yazılım entegrasyonuyla dönüştürüyor. Barkod/QR Kod okuyucusuyla, NUIW, ürünlerin besin değerleri, alerjenler ve daha fazlası gibi detaylı bilgilere anında erişim sağlar. Bilgi vermenin ötesinde, müşteri tercihlerine dayalı ileri önerilerle öne çıkan NUIW, şarap, atıştırmalıklar veya diğer ürünleri keşfederken seçenekleri genişleterek her etkileşimde yeni ve heyecan verici ürünleri keşfetmenizi sağlar. Perakendeciler için, NUIW bilgilendirilmiş satın almaları teşvik ederek müşterilere ürünler hakkında kapsamlı bilgiler sunar, kişiselleştirilmiş alışveriş deneyimleri sunar ve tamamlayıcı ürün önerileriyle sepet büyüklüğünü artırarak satışları ve müşteri memnuniyetini artırır. Bu sadece bir ekran değil; NUIW, müşterilerin ürünlerle etkileşim kurma şeklini yeniden tanımlayarak hem perakendecilere hem de alışveriş yapanlara fayda sağlayan dinamik ve kişiselleştirilmiş bir alışveriş deneyimi sunar.",
          "worksDescription3": "SprintPro X, maksimum 25 km hızla tasarlanmış bir hassas koşu bandı olup, sadece bir koşu makinesi değil, aynı zamanda vücut hareketinin tam potansiyelini açığa çıkaran doğal bir arayüz olarak hizmet verir. Bu özelliklerle birleştirilmiş olan yenilikçi SpeedSync Ekranı, EnduranceMetric Takipçi ve Milisaniye Hassasiyetli Zamanlayıcı ile zenginleştirilen bir deneyim sunar. Kendinizi yeni zirvelere taşıyın, hızınızı gerçek zamanlı olarak hassas bir şekilde takip edin ve EnduranceMetric Takipçi ile sınırlarınızı zorlayarak fitness yolculuğunuz için ölçütler belirleyin. Milisaniye Hassasiyetli Zamanlayıcı, yarışmalar için mükemmel bir doğruluk sunar. Elit sporcuların başarılarından ilham alan SprintPro X, mükemmeliyet standartlarına uyum sağlar ve yalnızca Sportive için özel olarak geliştirilmiş olup, İstanbul Spor ve Maraton Fuarı gibi etkinliklerde öne çıkmaktadır. Bu sadece bir koşu bandı değil; SprintPro X, etkileşimli zorluklar ve şampiyonlar için özel ödüller sunan fitness ve teknolojinin dinamik bir birleşimi olarak karşımıza çıkıyor.",
          "carouselTitle": "MOBİL UYUMLU KOLAYLAŞTIRILMIŞ ARAYÜZ, ÜRÜN GRUPLARI , REKLAM GÖSTERİM ALANI , QR OKUMA BUTONU",
          "carouselTitle2": "KELİME BULUTU",
          "carouselTitle3": "KELİME BULUTU",
          "carouselTitle4": "ÜRÜN DETAY ALANI",
          "carouselTitle5": "ÜRÜN DETAY ALANI",
          "carouselTitle6": "ÜRÜN DETAY ALANI",
          "carouselTitle7": "ÜRÜN ÖNERİLERİ , DETAYLI KARŞILAŞTIRMA PANELİ, ALTERNATİF ÖNERİLERİ",
          "galleryButton": "Resimleri Görüntüleyin",
          "workpageTitle": "Çalışmalar",
          "contactPage": "İletişim",
          "contactTitle": "Bize Ulaşın.",
          "contactLocation": "Adres",
          "contactEmail": "Email Adresi",
          "contactPhone": "Telefon Numarası",
          "footerRights": "Tüm Hakları Gizlidir."
        }
       }
     
  }
    
});


export default i18next;