import '../css/styles.css'
import 'bootstrap/dist/css/bootstrap.css';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../img/logo.png';
import '../translation/i18n';
import i18n from '../translation/i18n';
import {useTranslation} from 'react-i18next';
import { Dropdown } from 'react-bootstrap';
import ReactCountryFlag from "react-country-flag"

function NavigationBar() {

    


    const {t} = useTranslation()
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    }
    return (
        <>

            <Navbar expand="md" className='navbar'>
                <Navbar.Brand href="/" className='justify-content-start'>
                    <img
                        alt=""
                        src={logo}
                        className="brand-icon"/>
                </Navbar.Brand>
        

                <Navbar.Toggle className="navbar-toggle-button menu-button" data-bs-target="#navCollapse"
                               aria-controls="navCollapse" aria-expanded="false" aria-label="Toggle navigation"/>

                <Navbar.Collapse className='justify-content-end text-center' id='navCollapse'>
                    <Nav.Item>
                        <Nav.Link href="/" className='navbar-link-style'>{t('services')}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="/work" className='navbar-link-style'>{t('works')}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link href="/contact" className='navbar-link-style'>{t('contact')}</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                    <Dropdown>
             <Dropdown.Toggle variant="dark" id="dropdown-basic">
               {i18n.language}
            </Dropdown.Toggle>
            <Dropdown.Menu className='dropdown-menu-end'>
            <Dropdown.Item onClick={() => changeLanguage('en')}><ReactCountryFlag countryCode="US" />EN</Dropdown.Item>
            <Dropdown.Item onClick={() => changeLanguage('tr')}><ReactCountryFlag countryCode="TR" />TR</Dropdown.Item>
            </Dropdown.Menu>
            </Dropdown>
                    </Nav.Item>
        

                </Navbar.Collapse>
            </Navbar>
            <section id="language">
            <div className='container-fluid'>
            <div className='row'>
            <div className='col-sm-12 col-md-12 col-lg-12'>

            </div>
            </div>
            </div>
            </section>

        </>

    );
}


export default NavigationBar;