import '../css/styles.css'
import '../css/map.css'
import 'bootstrap/dist/css/bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLocationDot} from "@fortawesome/free-solid-svg-icons";
import {faEnvelope} from "@fortawesome/free-regular-svg-icons";
import {faMobileScreen} from "@fortawesome/free-solid-svg-icons";
import '../translation/i18n';
import {useTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet';


function ContactPage() {

    const {t} = useTranslation()
    return (

        <>

            <Helmet>
                <meta charSet="UTF-8"></meta>

                <title>Pangolin Tech │ {t('contactPage')}</title>
                <meta
                    name="description"
                    content="İletişim sayfamızda, işbirliği için kapılarımız her zaman açık! Sorularınızı, proje taleplerinizi veya işbirliği fırsatlarınızı paylaşmak için bize ulaşın. Profesyonel ekip üyelerimiz, size en iyi hizmeti sunmak için hazır bekliyor."
                />
                <meta
                    name="keywords"
                    content="İletişim Bilgileri, Bizimle İletişime Geçin, İşbirliği Talepleri, Sorularınız için Bize Ulaşın, İletişim Formu, İşbirliği İmkanları"
                />
            </Helmet>

            

            <section id="contact">

                <Container fluid>
                    <Row>
                        <Col sm="12" md="12" lg="12">
                        </Col>
                    </Row>
                    <h1 className="display-3 contact-text">{t('contactTitle')}</h1>
                </Container>

                <Container fluid>
                    <Row>

                        <Col lg="4" md="4" sm="12" className="contact-info">
                            <FontAwesomeIcon icon={faLocationDot}></FontAwesomeIcon>
                            <h5>{t('contactLocation')}</h5>
                            <p>Sanayi Mah. Teknopark Bul., 1/4C Z08, Pendik, Istanbul</p>


                        </Col>

                        <Col lg="4" md="4" sm="12" className="contact-info">
                            <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>
                            <h5>{t('contactEmail')}</h5>
                            <a href="mailto:iletisim@pangolinlab.com" target="_blank" rel="noreferrer">
                                <p>contact@pangolinlab.com</p></a>

                        </Col>

                        <Col lg="4" md="4" sm="12" className="contact-info">

                            <FontAwesomeIcon icon={faMobileScreen}></FontAwesomeIcon>
                            <h5>{t('contactPhone')}</h5>
                            <p> 0850 259 88 80 </p>


                        </Col>

                        <Col lg="12" md="12" sm="12" className="contact-info google-maps">

                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3014.812182744217!2d29.31182887603604!3d40.919863821363045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cada2298dc139d%3A0x45272051a4c651fb!2sPangolin!5e0!3m2!1str!2str!4v1700750711697!5m2!1str!2str"
                                width={600}
                                height={450}
                                className='map'
                                allowFullScreen=""
                                title='location'
                                referrerPolicy="no-referrer-when-downgrade"
                            ></iframe>

                        </Col>

                    </Row>
                </Container>
            </section>


        </>
    );
}

export default ContactPage;