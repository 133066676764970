import React from 'react';
import Navigation from "./navbar";
import Footer from "./footer";
import Services from "./Services";
import ContactPage from './Contact';
import Gallery from './Gallery';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

function App() {
    return (
        <React.StrictMode>
            <Navigation/>
            <Router>
                <Routes>
                    <Route exact path="/" element={<Services/>}/>
                    <Route path="/contact" element={<ContactPage/>}/>
                    <Route path="/work" element={<Gallery/>}/>
                </Routes>
            </Router>
            <Footer/>
        </React.StrictMode>
    );
}

export default App;