import '../css/styles.css'
import 'bootstrap/dist/css/bootstrap.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFacebookF} from '@fortawesome/free-brands-svg-icons';
import {faTwitter} from '@fortawesome/free-brands-svg-icons';
import {faInstagram} from '@fortawesome/free-brands-svg-icons';
import {faLinkedin} from '@fortawesome/free-brands-svg-icons';
import '../translation/i18n';
import {useTranslation} from 'react-i18next';

function Createfooter() {


    const {t} = useTranslation()
    return (
        <>


            <footer id="footer" className="text-lg-start">
                <div className="text-center p-3 text-dark ">

                    <p className="link-color">Copyright © {new Date().getFullYear()} Pangolin. {t('footerRights')}</p>
                    <a className='link-dark' href="https://www.facebook.com/pangolinlab/" target="_blank"
                       rel="noreferrer"><FontAwesomeIcon className='icon-padding' icon={faFacebookF}/></a>
                    <a className='link-dark' href="https://twitter.com/pangolinlab/" target="_blank"
                       rel="noreferrer"><FontAwesomeIcon className='icon-padding' icon={faTwitter}/></a>
                    <a className='link-dark' href="https://instagram.com/pangolintech/" target="_blank"
                       rel="noreferrer"><FontAwesomeIcon className='icon-padding' icon={faInstagram}/></a>
                    <a className='link-dark' href="https://www.linkedin.com/company/pangolinlab" target="_blank"
                       rel="noreferrer"><FontAwesomeIcon className='icon-padding' icon={faLinkedin}/></a>
                </div>
            </footer>


        </>
    );
}


export default Createfooter;