import '../css/styles.css'
import 'bootstrap/dist/css/bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import pic1 from '../img/1.jpg'
import pic2 from '../img/2.jpg'
import pic3 from '../img/3.jpg'
import pic4 from '../img/4.jpg'
import '../translation/i18n';
import {useTranslation} from 'react-i18next';
import {Helmet} from 'react-helmet';



function HomePage() {

    

    const {t} = useTranslation()
    return (<>

            <Helmet>
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
                <title>Pangolin Tech</title>
                <meta
                    name="description"
                    content="Yenilikçi yazılım geliştirme, tasarım ve güvenlik çözümleri ile donanım hizmetlerinde öncü olan şirketimiz, iş süreçlerinizi optimize etmek ve güvenliği artırmak için en son teknolojiyi kullanmaktadır. Müşteri odaklı yaklaşımımızla, özel çözümler sunarak işinizi ileri taşıyoruz."
                />
                <meta
                    name="keywords"
                    content="Yazılım Geliştirme, Yazılım Tasarımı, Güvenlik Çözümleri, Donanım Hizmetleri, İnovasyon ve Teknoloji"
                />
            </Helmet>

            
 

            <section id="statement">
                <Container fluid>
                    <Row>
                        <Col lg="6" md="12" sm="12" className='info-spacing'>
                            <img alt=""
                                 src={pic1}
                                 className="shadow-1-strong mb-2 hover-shadow"/>
                            <h3 className='statement-style'>{t('title')}</h3>
                            <p className='info-text'>
                                {t('description')}
                            </p>
                        </Col>
                        <Col lg="6" md="12" sm="12" className='info-spacing'>
                            <img alt=""
                                 src={pic2}
                                 className="shadow-1-strong mb-2 hover-shadow"/>
                            <h3 className='statement-style'>{t('title2')}</h3>
                            <p className='info-text'>
                                {t('description2')}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="6" md="12" sm="12" className='info-spacing'>
                            <img alt=""
                                 src={pic3}
                                 className="shadow-1-strong mb-2 hover-shadow"/>
                            <h3 className='statement-style'>{t('title3')}</h3>
                            <p className='info-text'>
                                {t('description3')}
                            </p>
                        </Col>
                        <Col lg="6" md="12" sm="12" className='info-spacing'>
                            <img alt=""
                                 src={pic4}
                                 className="shadow-1-strong mb-2 hover-shadow"/>
                            <h3 className='statement-style'>{t('title4')}</h3>
                            <p className='info-text'>
                                {t('description4')}
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>


        </>);
}


export default HomePage;
